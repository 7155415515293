const componentInstances = new WeakMap();

export function createComponents(element) {
    const componentElements = element.querySelectorAll('[data-component]');
    for (let element of componentElements) {
        if (componentInstances.has(element)) {
            continue;
        }

        loadComponent(element.dataset.component, element);
    }
}

export function destroyComponents(element) {
    const componentElements = element.querySelectorAll('[data-component]');
    for (let element of componentElements) {
        if (!componentInstances.has(element)) {
            continue;
        }

        const instance = componentInstances.get(element);
        componentInstances.delete(element);
        if (typeof instance.destroy === 'function') {
            // Schedule the destruction of the component instance in the next event loop.
            setTimeout(() => instance.destroy(), 0);
        }
    }
}

export async function loadComponent(componentName, element) {
    const component = await import('../components/' + componentName + '.js');
    const instance = component.default(element);
    if (instance !== undefined) {
        componentInstances.set(element, instance);
    }
}
